<template>
  <div class="page-main">
    <img
      src="../../assets/image/contact_logo.png"
      alt=""
      srcset=""
      style="width: 100%"
    />
    <div style="margin-top: 30px">
      <nqForm
        ref="form"
        :values="value"
        @submit="sbumitForm"
        :formList="[
          {
            label: '目的地',
            type: 'countrySelect',
            key: 'nationId',
          },
          {
            label: '货物重量（（KG）公斤）',
            type: 'input',
            key: 'cargoWeight',
            validate: [
              (list, item, index) => {
                return {
                  validateRes: !isNaN(item.value),
                  message: '请输入数字',
                };
              },
            ],
          },
        ]"
      ></nqForm>
      <div class="form-item padding-10">
        <div class="form-item-label">请输入体积</div>
        <div class="chicun">
          <div>
            <div>长（cm）</div>
            <input type="text" class="form-item-ele" v-model="cc.length1" />
            <div class="error-tips">{{ lenthError }}</div>
          </div>
          <div style="margin: 0px 10px">
            <div>宽（cm）</div>
            <input type="text" class="form-item-ele" v-model="cc.width" />
            <div class="error-tips">{{ widthError }}</div>
          </div>
          <div>
            <div>高（cm）</div>
            <input type="text" class="form-item-ele" v-model="cc.height" />
            <div class="error-tips">{{ heightError }}</div>
          </div>
        </div>
      </div>
      <div class="notes-box">
        注：国际物流中有体积重的说法，一般情况下（邮政除外）体积重与实重两者取最大值。
        <br />
        体积重计算公式：长*宽*高/体积系数
      </div>
      <div class="button-box">
        <div class="nq-button" @click="searchRes">点击查询</div>
      </div>
      <ul class="fee-list padding-10" style="margin-top: 50px">
        <li class="fee-list-item" v-for="item in feeList" :key="item">
          <div class="fee-list-item-row" style="align-items: center">
            <div>
              <img src="../../assets/image/fy_33.png" style="height: 25px" />
            </div>
            <div>计费重量：{{ item.weight }}kg</div>
            <div style="margin-left:20px">渠道名称：{{ item.lineName }}</div>
          </div>
          <div class="fee-list-item-row tiaozheng">
            <div>时效：{{ item.sendTime }}</div>
            <div class="fee-item-row-right">
              总费用：<span style="color: #0cb1f3">{{ item.price }}</span>
            </div>
          </div>
          <div class="fee-list-item-row">
            <div class="to-deital" @click="toGetDetail(item)">查看详情</div>
          </div>
        </li>
      </ul>
      <div id="myDiv"></div>
    </div>
    <nqDialog
      :hideHeader="true"
      :visible="visible"
      @close="visible = false"
      title="查看详情"
    >
      <div style="min-height: 100px; white-space: pre-wrap" v-html="currentDetail"></div>
      <div style="text-align: center">
        <div class="nq-button" @click="visible = false">关闭</div>
      </div>
    </nqDialog>
  </div>
</template>
<script>
import { yunfee } from '../../utils/apis/apis.js'
export default {
  name: 'feeCompute',
  data() {
    return {
      visible: false,
      lenthError: '',
      heightError: '',
      widthError: '',
      feeList: [],
      currentDetail: '',
      cc: {
        width: '',
        height: '',
        length1: ''
      },
      value: {
        nationId: '',
        cargoWeight: ''
      },
      formParams: {}
    }
  },
  methods: {
    toGetDetail(item) {
      this.visible = true
      this.currentDetail = item.details
    },
    sbumitForm(data) {
      this.formParams = data
      this.widthError = ''
      this.heightError = ''
      this.lenthError = ''
      let num = 0
      if (this.cc.length1 !== '' && isNaN(this.cc.length1)) {
        this.lenthError = '请输入数字'
        num++
      }
      if (this.cc.width !== '' && isNaN(this.cc.width)) {
        this.widthError = '请输入数字'
        num++
      }
      if (this.cc.height !== '' && isNaN(this.cc.height)) {
        this.heightError = '请输入数字'
        num++
      }
      if (num > 0) {
        return
      }
      this.feeList = []
      this.$reqPost(yunfee.info, {
        ...this.formParams,
        ...{
          length: this.cc.length1,
          width: this.cc.width,
          height: this.cc.height
        }
      }).then((res) => {
        if (res.data.code === 200) {
          this.feeList = res.data.data
          this.$nextTick(() => {
            document.getElementById('myDiv').scrollIntoView()
          })
        } else {
          this.$toast(res.data.messgae)
        }
      })
    },
    searchRes() {
      this.$refs.form.submitForm()
    }
  }
}
</script>
<style scoped>
.chicun {
  display: flex;
}
.fee-list li {
  box-shadow: 3px 3px 5px #efefef;
  padding: 5px;
  margin-bottom: 8px;
  border: 1px solid #ddd;
}
.fee-list-item-row {
  display: flex;
  align-items: top;
  font-size: 13px;
  margin-bottom: 5px;
}
.tiaozheng {
  display: flex;
  flex-direction: column;
  line-height: 22px;
}
.fee-item-row-right {
  /* width: 100px; */
  /* padding-left: 12px; */
}
.to-deital {
  background: #0cb1f3;
  padding: 0px 25px;
  color: #fff;
  font-size: 14px;
  box-shadow: 5px 0 10px #aaa;
  display: inline;
}
.chicun > div {
  font-size: 14px;
}
.error-text {
  height: 20px;
  line-height: 20px;
  color: red;
  font-size: 14px;
  margin-bottom: 8px;
}
.form-item-label {
  font-size: 15px;
  margin-bottom: 3px;
}
.form-item-ele {
  height: 35px;
  width: 90%;
  padding-left: 8px;
}
.form-end {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 30px;
}
.item-inline {
  display: inline-block;
}
.notes-box {
  display: block;
  width: 80%;
  font-size: 14px;
  margin: 20px auto;
  border: 1px solid #0cb1f3;
  border-radius: 5px;
  padding: 8px;
}
.error-tips {
  color: red;
}
</style>
